import React, { useCallback, useEffect, useState } from 'react';
import { Form, InputGroup, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { CgShoppingBag } from 'react-icons/cg';
import { RiLoginBoxLine } from "react-icons/ri";
import { CiSearch } from 'react-icons/ci';
import { GrUserManager } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { DLT } from '../Redux/actions/CartAction';
import Badge from '@mui/material/Badge';
import CartBody from './CartBody';

const HeaderNav = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [price, setPrice] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const getdata = useSelector((state) => state.cartreducer.carts);
    // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isLoggedIn = useSelector(state => state.auth.user);


    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        navigate(`/shop?search=${searchQuery}`);
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const dlt = (id) => {
        dispatch(DLT(id))
    }

    const total = useCallback(() => {
        let totalPrice = 0;
        getdata.forEach((ele, k) => {
            totalPrice = ele.price * ele.qnty + totalPrice;
        });
        setPrice(totalPrice);
    }, [getdata]);

    useEffect(() => {
        total();
    }, [total])
    return (
        <>
            <Navbar expand="lg" className="nav_bar" data-bs-theme="dark">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <div className='row' >

                        <div className="header_input">
                            <form onSubmit={handleSearch} className='searchbar'>
                                <InputGroup className="mb-3 ">
                                    <Form.Control
                                        placeholder="Search All Products"
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                    />
                                    <button type='submit'><CiSearch /></button>
                                </InputGroup>
                            </form>
                            {isLoggedIn ? (
                                <NavLink to='dashboard/overview'>
                                    <i className='header_input_icon'><GrUserManager /></i>
                                </NavLink>
                            ) : (
                                <NavLink to='/login'>
                                    <i className='header_input_icon'><RiLoginBoxLine /></i>
                                </NavLink>
                            )}
                            <NavLink to='#'>
                                <Badge badgeContent={getdata.length} color="primary"
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <i className='header_input_icon' ><CgShoppingBag /></i>
                                </Badge>
                            </NavLink>
                        </div>
                        <Nav className="nav_bar_a">
                            <NavLink to='/' className='nav_link'>Home</NavLink>
                            <NavLink to='about' className='nav_link'>About</NavLink>
                            <NavDropdown title="Shop" id="basic-nav-dropdown" className='navs_link'>
                                <NavDropdown.Item as={Link} to="/shop?search=face care" className='nav_dropdown'>Face Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/shop?search=hair care" className='nav_dropdown'>Hair Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/shop?search=body care" className='nav_dropdown'>Body Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/shop?search=eye Care" className='nav_dropdown'>Eye Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/shop?search=lip Care" className='nav_dropdown'>Lip Care</NavDropdown.Item>
                            </NavDropdown>
                            <NavLink to='testimonials' className='nav_link'>Testimonials</NavLink>
                        </Nav>
                    </div>
                </Navbar.Collapse>
            </Navbar>
            <CartBody
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                getdata={getdata}
                dlt={dlt}
                price={price}
            />
        </>
    )
}

export default HeaderNav;
