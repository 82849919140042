import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loading from '../../components/Loading ';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    let userInfo = JSON.parse(localStorage.getItem('user-info'));

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get('https://backend.fronteda.devhub.digtalsdesigns.com/api/orders/' + userInfo.id);
                setOrders(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching orders:', error);
                setError('Failed to fetch orders. Please try again later.');
                setLoading(false);
            }
        };
        fetchOrders();
    }, []);
    if (loading) {
        return <><Loading /></>;
    }
    return (
        <>
            <div className="dash-card about-bg">
                <Card className='dash-sub-card'>
                    <Card.Header as="h4" className="dash-card-header">My Orders</Card.Header>
                    <Card.Body>
                        <Table striped bordered hover variant="light">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Order Date</th>
                                    <th>Name</th>
                                    <th>Order Status</th>
                                    <th>Total</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map(order => (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.created_at}</td>
                                        <td>{order.customer_name}</td>
                                        <td>{order.status}</td>
                                        <td>{order.total}</td>
                                        {/* <td>
                                            <Link to={`/invoice-show/${order.id}`}>
                                                <span className="btn btn-primary">View</span>
                                            </Link>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default Orders;
