import React from 'react'
import OurBrands from '../../components/OurBrands'
import OurProducts from '../../components/OurProducts'
import OurBestSell from '../../components/OurBestSell'
import HeroSlider from '../../components/HeroSlider'

const Home = () => {
    return (
        <>
            <HeroSlider />
            <div className='background-img'>
            <OurBrands />
            <OurProducts />
            <OurBestSell />
            </div>
        </>
    )
}

export default Home