import React, { useEffect, useState, useCallback } from 'react';
import SectionHeader from '../../components/SectionHeader';
import HeaderImage from '../../images/section-herder-img.jpg';
import NoImage from '../../images/NoImage.jpg';
import { MdDelete } from "react-icons/md";
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DLT, ADD, REMOVE } from '../../Redux/actions/CartAction';

const Cart = () => {
    const [data, setData] = useState([]);
    const [price, setPrice] = useState(0);

    const { id } = useParams();
    const history = useNavigate();
    const dispatch = useDispatch();

    const getdata = useSelector((state) => state.cartreducer.carts);
    console.log('cart asdasdasd getdata', getdata);

    const compare = useCallback(() => {
        let comparedata = getdata.filter((e) => e.id === id);
        setData(comparedata);
    }, [getdata, id]);

    const send = (e) => {
        dispatch(ADD(e));
    }

    const dlt = (id) => {
        dispatch(DLT(id));
        history("/cart");
    }

    const remove = (item) => {
        dispatch(REMOVE(item));
    }

    const total = useCallback(() => {
        let totalPrice = 0;
        getdata.forEach((ele) => {
            totalPrice += ele.price * ele.qnty;
        });
        setPrice(totalPrice);
    }, [getdata]);

    useEffect(() => {
        compare();
        total();
    }, [id, getdata, compare, total]);

    return (
        <>
            <SectionHeader title='Cart' image={HeaderImage} PageLink='Cart'></SectionHeader>
            <div className="main_cart">
                <Container>
                    <Row>
                        <Col md={8}>
                            <div className="inner__cart">
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="Product">
                                                Product
                                            </th>
                                            <th scope="col" className="pname-quan">
                                                Name
                                            </th>
                                            <th scope="col" className="pname-quan">
                                                Quantity
                                            </th>
                                            <th scope="col" className="pname-total">
                                                Price
                                            </th>
                                            <th scope="col" className="pname-total">
                                                Remove
                                            </th>
                                        </tr>
                                    </thead>
                                    {getdata.map((ele, index) => (
                                        <tbody key={index}>
                                            <tr>
                                                <td>
                                                    <div className="cart_product">
                                                        {ele.imagestwo ? (
                                                            <Link to={`/SingleProduct/${ele.id}`}>
                                                                <img src={ele.imagestwo} alt="" />
                                                            </Link>
                                                        ) : (
                                                            <img src={NoImage} alt="" />
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>{ele.slug}</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className='cart-qnty'>
                                                        <button className='cart-qnty-span' onClick={ele.qnty <= 1 ? () => dlt(ele.id) : () => remove(ele)}>-</button>
                                                        <span>{ele.qnty}</span>
                                                        <button className='cart-qnty-span' onClick={() => send(ele)}>+</button>
                                                    </div>
                                                </td>
                                                <td>{ele.price}</td>
                                                <td className='cart_body_details_dlt' onClick={() => dlt(ele.id)}>
                                                    <MdDelete />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </Table>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="total__tab_section">
                                <div className="totla__inner">
                                    <span>Total</span>
                                    <div className="total__price">
                                        <p>$ {price}</p>
                                    </div>
                                </div>
                                <div className="bottom__total">
                                    <Link to="/checkOut" className="chect_out_btn">
                                        Check Out
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Cart;
