import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaStar } from "react-icons/fa";
import { FaStarHalfStroke } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa6";
import { useParams } from 'react-router';

const ProductReview = () => {
    const [reviews, setReviews] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchReview = async () => {
            try {
                const response = await axios.get(`https://backend.fronteda.devhub.digtalsdesigns.com/api/product-reviews/${id}`);
                setReviews(response.data.average_rating);
            } catch (error) {
                console.error('Error fetching product review:', error);
            }
        };
        fetchReview();
    }, [id]);

    const renderStars = (rating) => {
        const starComponents = [];
        const wholeStars = Math.floor(rating);
        const decimalPart = rating - wholeStars;

        // Whole stars
        for (let i = 0; i < wholeStars; i++) {
            starComponents.push(<FaStar key={i} />);
        }
        // Half-filled star
        if (decimalPart >= 0.5) {
            starComponents.push(<FaStarHalfStroke key="half" />);
        }
        // Empty stars
        const remainingStars = 5 - Math.ceil(rating);
        for (let i = 0; i < remainingStars; i++) {
            starComponents.push(<FaRegStar key={`empty-${i}`} style={{ color: "#818286" }} />);
        }
        return starComponents;
    };
    return (
        <>
            <div>
                <div className='star'>
                    {reviews ? (
                        renderStars(reviews)
                    ) : (
                        Array.from({ length: 5 }, (_, index) => <FaRegStar key={index} />)
                    )}
                </div>
            </div>
        </>
    )
}

export default ProductReview