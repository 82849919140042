import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SectionHeader from '../../components/SectionHeader'
import NoImage from "../../images/NoImage.jpg"
import HeaderImage from '../../images/section-herder-img.jpg'
import { FaStar } from "react-icons/fa";
import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import Loading from '../../components/Loading ';

const Testimonials = () => {
    const [testimonial, setTestimonial] = useState([]);
    const baseURL = "https://backend.fronteda.devhub.digtalsdesigns.com/public/";
    useEffect(() => {
        axios.get('https://backend.fronteda.devhub.digtalsdesigns.com/api/testimonials-show')
            .then(response => {
                setTestimonial(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);
    useEffect(() => {
        AOS.init();
    }, []);
    if (!testimonial) {
        return <div><Loading /></div>;
    }
    return (
        <>
            <SectionHeader title='Testimonials' image={HeaderImage} PageLink='Testimonials'></SectionHeader>
            <section className='testimonial-bg'>
                <Container>
                    <div className="testimonial-cards" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear">
                        {testimonial.length > 0 ? (
                            testimonial.map(({ id, title, image, description }) => {
                                return (
                                    <Card className='testimonial_card' key={id}>
                                        {image ? (
                                            <Card.Img className="testimonial_card_img" variant="top"
                                                src={`${baseURL}${image}`}
                                            />
                                        ) : (
                                            <Card.Img className="testimonial_card_img" variant="top" src={NoImage} alt="Product img" />
                                        )}
                                        <Card.Body>
                                            <div className="star">
                                                <i><FaStar /></i>
                                                <i><FaStar /></i>
                                                <i><FaStar /></i>
                                                <i><FaStar /></i>
                                                <i><FaStar /></i>
                                            </div>
                                            <Card.Text className='testimonial_card_text'>
                                                <div dangerouslySetInnerHTML={{ __html: description }} />
                                            </Card.Text>
                                            <h5 className='testimonial_card_name'>{title}</h5>
                                        </Card.Body>
                                    </Card>
                                )
                            })
                        ) : (
                            <Loading />
                        )}
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Testimonials