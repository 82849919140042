import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import SectionHeader from '../../components/SectionHeader'
import HeaderImage from '../../images/section-herder-img.jpg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import axios from 'axios'
import PaymentOptions from '../paymentOptions/PaymentOptions'

const CheckOut = () => {
    // State for user information
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [Address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [price, setPrice] = useState(0);

    // Fetch user details from Redux store
    const userDetails = useSelector(state => state.auth.user);
    useEffect(() => {
        if (userDetails) {
            setFirstName(userDetails.name);
            setEmail(userDetails.email);
            setContact(userDetails.phone);
        }
        // console.log(userDetails);
    }, [userDetails]);

    const customerId = useSelector(state => state.auth.userId);
    const getdata = useSelector((state) => state.cartreducer.carts);
    useEffect(() => {
        calculateTotal();
    }, [getdata]);

    const calculateTotal = () => {
        let totalPrice = 0;
        getdata.forEach((item) => {
            totalPrice += item.price * item.qnty;
        });
        setPrice(totalPrice);
    };

    const placeOrder = async () => {
        // Check if any required field is empty
        if (firstName === '' || email === '' || contact === '' || city === '' || country === '' || state === '' || zipCode === '' || !localStorage.getItem('selectedPaymentMethod')) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please fill in all required fields.'
            });
            return;
        }

        // Check if email is valid
        if (!email.includes('@')) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please enter a valid email address.'
            });
            return;
        }

        // Proceed with placing the order
        // let orderItems = JSON.parse(localStorage.getItem('cart'));
        // let paymentMethod = localStorage.getItem('selectedPaymentMethod') ?? 'cashOnDelivery';
        let data = {
            firstName,
            email,
            contact,
            country,
            state,
            city,
            zipCode,
            Address,
            price,
            customer_id: customerId,
        };
        console.log("Order data:", data);
        try {
            const response = await axios.post('https://backend.fronteda.devhub.digtalsdesigns.com/api/checkout', data);
            console.log('Order placed successfully:', response.data);
            Swal.fire('Order placed successfully:');
        } catch (error) {
            console.error("Error:", error);
            Swal.fire('An error occurred. Please try again later.');
        }
    };
    return (
        <div>
            <SectionHeader title='Check Out' image={HeaderImage} PageLink='Check Out'></SectionHeader>
            <div className="check_out_sec">
                <Container>
                    <Row>
                        <Col md={7}>
                            <div className="check_out_inputs">
                                <Row className="check_out_input">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>First Name <span className='red_star'> *</span></Form.Label>
                                        <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </Form.Group>
                                </Row>
                                <Row className="check_out_input">
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>Email <span className='red_star'> *</span></Form.Label>
                                        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>Contact <span className='red_star'> *</span></Form.Label>
                                        <Form.Control type="text" value={contact} onChange={(e) => setContact(e.target.value)} />
                                    </Form.Group>
                                </Row>
                                <Row className="check_out_input">
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>Country <span className='red_star'> *</span></Form.Label>
                                        <Form.Control type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>State <span className='red_star'> *</span></Form.Label>
                                        <Form.Control type="text" value={state} onChange={(e) => setState(e.target.value)} />
                                    </Form.Group>
                                </Row>
                                <Row className="check_out_input">
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>City <span className='red_star'> *</span></Form.Label>
                                        <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>ZIP Code <span className='red_star'> *</span></Form.Label>
                                        <Form.Control type="number" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                                    </Form.Group>
                                </Row>
                                <Row className="check_out_input">
                                    <Col md={12}>
                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Address <span className='red_star'> *</span></Form.Label>
                                            <Form.Control type="address" value={Address} onChange={(e) => setAddress(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="check_out_table_2 sticky-top">
                                <h4>Your Order</h4>
                                <div className="content">
                                    <ul>
                                        <li>Sub Total <span>$ {price}</span></li>
                                        <li className='last'>Total <span id='totall'>$ {price}</span></li>
                                    </ul>
                                </div>
                                <div className='single-widget'>
                                    <h4>PAYMENT METHODS</h4>
                                    <PaymentOptions />
                                    <div className='bottom__total'>
                                        <button className='proceed_to_checkout_btn' onClick={placeOrder}>Proceed to Payment</button>
                                    </div>
                                    <div className='bottom_total'>
                                        <button className='proceed_to_checkout_btn'>
                                            <Link to="/shop" >Continue Shopping</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default CheckOut