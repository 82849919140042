import React, { useState } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import '../login/login.css';

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.cpassword) { // Fix the comparison here
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Password & Confirm Password do not match'
      });
    } else {
      // Check if any field is empty
      if (!formData.name || !formData.email || !formData.password) {
        Swal.fire('Please fill out all fields.')
        // You can display an error message to the user
        return;
      }
  
      // Check if password length is at least 6 characters
      if (formData.password.length < 4) {
        Swal.fire('Password must be at least 6 characters long.')
        return;
      }
      try {
        let baseUrl = process.env.REACT_APP_BASE_URL || 'https://backend.fronteda.devhub.digtalsdesigns.com';
        const response = await axios.post(
          baseUrl + '/api/register',
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          console.log("User registered successfully.");
        } 
        console.log("response:", response);
      } catch (error) {
        console.error("Error:", error);
        Swal.fire(error)
      }
      // Store userInfo in localStorage
      localStorage.setItem("userInfo", JSON.stringify(formData));
      Swal.fire('User info submitted successfully')
      // Navigate to "/"
      navigate("/login");
    }
  };
  return (
    <>
      <section className="login-bg">
        <div className="main_login_signup">
          <Container>
            <Row className="row_login_signup">
              <Col lg={6}>
                <div className="main_lognin">
                  <form onSubmit={handleSubmit}>
                  <h1>Signin To Your Account</h1>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                    />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                    />
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Your Password"
                    />
                    <input
                      type="password"
                      name="cpassword"
                      value={formData.cpassword}
                      onChange={handleChange}
                      placeholder="confire Password"
                    />
                    <button type="submit" className="theme-btn">SignUp</button>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
export default Register;