import React from 'react'


const Loading = () => {
    return (
        <>
            <br /><br /><br />
            <br /><br /><br />
            <div class="spinner">
                <div class="loader" id="loader"></div>
                <div class="loader" id="loader2"></div>
                <div class="loader" id="loader3"></div>
                <div class="loader" id="loader4"></div>
                <span id="text">LOADING...</span><br />
            </div>
        </>
    )
}

export default Loading 