import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import SectionHeader from '../../components/SectionHeader'

import HeaderImage from '../../images/section-herder-img.jpg'

import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../../Redux/actions/settingsActions';


const About = () => {


    const dispatch = useDispatch();
    const { settings, loading, error } = useSelector(state => state.settings);

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);


    
    // ************ CMS ************
    const about_us_Banner = settings.find(setting => setting.name === 'about_us_Banner');
    const about_title = settings.find(setting => setting.name === 'about_title');
    const about_sub_title = settings.find(setting => setting.name === 'about_sub_title');
    const about_sub_title_two = settings.find(setting => setting.name === 'about_sub_title_two');
    const about_image_title = settings.find(setting => setting.name === 'about_image_title');
    const about_image_sub_title = settings.find(setting => setting.name === 'about_image_sub_title');
    const about_us_image = settings.find(setting => setting.name === 'about_us_image');
    

    const baseImageUrl = 'https://backend.fronteda.devhub.digtalsdesigns.com/public/uploads/media/';
    const imageUrl = about_us_image?.value ? `${baseImageUrl}${about_us_image.value}` : ''; // Construct the full image URL
    const bannerimg = about_us_Banner?.value ? `${baseImageUrl}${about_us_Banner.value}` : ''; // Construct the full image URL
    
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <SectionHeader title='About' image={bannerimg} PageLink='About us'></SectionHeader>
            <div className="about-bg">
                <Container className='padding'>

                    <Row>
                        <Col xs={12} md={6}>
                            <div className="about_img">
                                <img src={imageUrl} alt="" data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear" />
                                <div className="about_mission" data-aos="fade-right" data-aos-duration="4000" data-aos-easing="linear">
                                    <h4>
                                        {about_image_title?.value}
                                    </h4>
                                    <p>
                                        {about_image_sub_title?.value}
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="section_head_two" data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear">
                                <h2>{about_title?.value}</h2>
                                <div data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear">
                                    <p className="section_head_two_p">
                                        {about_sub_title?.value}
                                    </p>
                                    <p className="section_head_two_p">
                                        {about_sub_title_two?.value}

                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default About


// {setting1 && (
//     <div>
//       <p>Name: {setting1.name}</p>
//       <p>Value: {setting1.value}</p>
//     </div>
//   )}
//   {setting2 && (
//     <div>
//       <p>Name: {setting2.name}</p>
//       <p>Value: {setting2.value}</p>
//     </div>
//   )}