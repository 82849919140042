import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SectionName from './SectionName'
import { Link } from 'react-router-dom';
import NoImage from "../images/NoImage.jpg"
import Icon from "../images/FaceCare.png"
import Icon1 from "../images/eye.png"
import Icon2 from "../images/lipstick.png"
import Icon3 from "../images/nail.png"
import Icon4 from "../images/shampoo.png"
import { FaStar } from "react-icons/fa";
import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';

const OurProducts = () => {
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Face Care');
  
  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector(state => state.settings);
  
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  
  const products_title = settings.find(setting => setting.name === 'products_title');

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let baseUrl = process.env.REACT_APP_BASE_URL || 'https://backend.fronteda.devhub.digtalsdesigns.com';
        const response = await axios.get(baseUrl + '/api/show-products');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error.response);
      }
    };
    fetchProducts();
  }, []);

  const filteredProducts = products.filter(product => product.category === selectedCategory);
  const handleTabSelect = (category) => {
    setSelectedCategory(category);
  };

  return (
    <section>
      <Container>
        <div className="line"><hr /></div>
        <SectionName title={products_title?.value} />
        <Tabs
          defaultActiveKey="Face Care"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={handleTabSelect}
        >
          <Tab eventKey="Face Care" title={
            <div className="best_sell_icon">
              <img src={Icon} alt="" />
              <p>Face Care</p>
            </div>}>
            <div className='cards'
              data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear"
            >
              {filteredProducts.map(({ id, price, slug, special_price, description, category, imagestwo }) => {
                return (
                  <Card className="all_card" key={id}>
                    {/* <Card.Img className="card_img" variant="top" src="https://backend.fronteda.devhub.digtalsdesigns.com/storage/app/media/RRpPDRYSJpKCpCKiuY5JRZhVcRbMEKwGjLIat1CD.jpg" /> */}
                    {imagestwo ? (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top"
                          src={imagestwo.replace('/storage', '/storage/app/').replace(/[\\"[\]]/g, '')}
                        />
                      </Link>
                    ) : (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top" src={NoImage} alt="Product img" />
                      </Link>
                    )}
                    <Card.Body>
                      <div className="star">
                        {[...Array(5)].map((_, index) => (
                          <i key={index}><FaStar /></i>
                        ))}
                      </div>
                      <Card.Title>{slug}</Card.Title>
                      <div className="card_footer">
                        <div className="card_price">
                          <p className="discount_price">{price}</p>
                          <p>{special_price}</p>
                          <p>{category}</p>
                        </div>
                        <button className='custom_btn_card'><Link to="shop">Shop Now</Link>  </button>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </Tab>

          <Tab eventKey="Eye Care" title={
            <div className="best_sell_icon">
              <img src={Icon1} alt="" />
              <p>Eye Care</p>
            </div>
          }>
            <div className='cards'>
              {filteredProducts.map(({ id, price, slug, special_price, description, category, imagestwo }) => {
                return (
                  <Card className="all_card" key={id}>
                    {imagestwo ? (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top" src={imagestwo} />
                      </Link>
                    ) : (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top" src={NoImage} alt="Product img" />
                      </Link>
                    )}
                    <Card.Body>
                      <div className="star">
                        {[...Array(5)].map((_, index) => (
                          <i key={index}><FaStar /></i>
                        ))}
                      </div>
                      <Card.Title>{slug}</Card.Title>
                      <div className="card_footer">
                        <div className="card_price">
                          <p className="discount_price">{price}</p>
                          <p>{special_price}</p>
                          <p>{category}</p>
                        </div>
                        <button className='custom_btn_card'><Link to="shop">Shop Now</Link> </button>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </Tab>

          <Tab eventKey="Lip Care" title={
            <div className="best_sell_icon">
              <img src={Icon2} alt="" />
              <p>Lip Care</p>
            </div>
          }>
            <div className='cards'>
              {filteredProducts.map(({ id, price, slug, special_price, description, category, imagestwo }) => {
                return (
                  <Card className="all_card" key={id}>
                    {imagestwo ? (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top" src={imagestwo} />
                      </Link>
                    ) : (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top" src={NoImage} alt="Product img" />
                      </Link>
                    )}
                    <Card.Body>
                      <div className="star">
                        {[...Array(5)].map((_, index) => (
                          <i key={index}><FaStar /></i>
                        ))}
                      </div>
                      <Card.Title>{slug}</Card.Title>
                      <div className="card_footer">
                        <div className="card_price">
                          <p className="discount_price">{price}</p>
                          <p>{special_price}</p>
                          <p>{category}</p>
                        </div>
                        <button className='custom_btn_card'><Link to="shop">Shop Now</Link> </button>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </Tab>

          <Tab eventKey="Body Care" title={
            <div className="best_sell_icon">
              <img src={Icon3} alt="" />
              <p>Body Care</p>
            </div>
          }>
            <div className='cards'>
              {filteredProducts.map(({ id, price, slug, special_price, description, category, imagestwo }) => {
                return (
                  <Card className="all_card" key={id}>
                    {imagestwo ? (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top" src={imagestwo} />
                      </Link>
                    ) : (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top" src={NoImage} alt="Product img" />
                      </Link>
                    )}
                    <Card.Body>
                      <div className="star">
                        {[...Array(5)].map((_, index) => (
                          <i key={index}><FaStar /></i>
                        ))}
                      </div>
                      <Card.Title>{slug}</Card.Title>
                      <div className="card_footer">
                        <div className="card_price">
                          <p className="discount_price">{price}</p>
                          <p>{special_price}</p>
                          <p>{category}</p>
                        </div>
                        <button className='custom_btn_card'><Link to="shop">Shop Now</Link> </button>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </Tab>

          <Tab eventKey="Hair Care" title={
            <div className="best_sell_icon">
              <img src={Icon4} alt="" />
              <p>Hair Care</p>
            </div>
          }>
            <div className='cards'>
              {filteredProducts.map(({ id, price, slug, special_price, description, category, imagestwo }) => {
                return (
                  <Card className="all_card" key={id}>
                    {imagestwo ? (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top" src={imagestwo} />
                      </Link>
                    ) : (
                      <Link to={"/single-product/" + id}>
                        <Card.Img className="card_img" variant="top" src={NoImage} alt="Product img" />
                      </Link>
                    )}
                    <Card.Body>
                      <div className="star">
                        {[...Array(5)].map((_, index) => (
                          <i key={index}><FaStar /></i>
                        ))}
                      </div>
                      <Card.Title>{slug}</Card.Title>
                      <div className="card_footer">
                        <div className="card_price">
                          <p className="discount_price">{price}</p>
                          <p>{special_price}</p>
                          <p>{category}</p>
                        </div>
                        <button className='custom_btn_card'><Link to="shop">Shop Now</Link> </button>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </Tab>

        </Tabs>
        <div className="products_btn">
          <button class="custom_btn"><Link to="/shop">View All Products</Link></button>
        </div>
      </Container>
    </section>
  )
}

export default OurProducts