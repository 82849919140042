import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import SectionName from './SectionName'

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Image from "../images/Layer-52.jpg"
import Image2 from "../images/Layer-53.jpg"
import Image3 from "../images/Layer-54.jpg"

import Card from 'react-bootstrap/Card';
import { FaStar } from "react-icons/fa";
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';


const OurBestSell = () => {
  var setting = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows:false ,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector(state => state.settings);
  
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  
  const best_seller_title = settings.find(setting => setting.name === 'best_seller_title');

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section>
      <Container>
      <div className="line2">
          <hr />
        </div>
        <SectionName title={best_seller_title?.value} />


        <div className="best_sell" >
          <Slider {...setting} 
          >
            <Card data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear" >
              <Card.Img className='card_img' variant="top" src={Image} />
              <Card.Body>
                <div className="star">
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                </div>
                <Card.Title>Product NAME</Card.Title>
                <div className="card_footer">
                  <div className='card_price' >
                    <p className='discount_price'>$4.00</p>
                    <p>$4.00</p>
                  </div>
                  <button className='custom_btn_card'><Link to="/">Shop Now</Link>  </button>
                </div>
              </Card.Body>
            </Card>

            <Card data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear" >
              <Card.Img className='card_img' variant="top" src={Image3} />
              <Card.Body>
                <div className="star">
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                </div>
                <Card.Title>Product NAME</Card.Title>
                <div className="card_footer">
                  <div className='card_price' >
                    <p className='discount_price'>$4.00</p>
                    <p>$4.00</p>
                  </div>
                  <button className='custom_btn_card'><Link to="/">Shop Now</Link>  </button>
                </div>
              </Card.Body>
            </Card>

            <Card data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear" >
              <Card.Img className='card_img' variant="top" src={Image2} />
              <Card.Body>
                <div className="star">
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                </div>
                <Card.Title>Product NAME</Card.Title>
                <div className="card_footer">
                  <div className='card_price' >
                    <p className='discount_price'>$4.00</p>
                    <p>$4.00</p>
                  </div>
                  <button className='custom_btn_card'><Link to="/">Shop Now</Link>  </button>
                </div>
              </Card.Body>
            </Card>

            <Card data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear" >
              <Card.Img className='card_img' variant="top" src={Image3} />
              <Card.Body>
                <div className="star">
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                </div>
                <Card.Title>Product NAME</Card.Title>
                <div className="card_footer">
                  <div className='card_price' >
                    <p className='discount_price'>$4.00</p>
                    <p>$4.00</p>
                  </div>
                  <button className='custom_btn_card'><Link to="/">Shop Now</Link>  </button>
                </div>
              </Card.Body>
            </Card>

            <Card data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear" >
              <Card.Img className='card_img' variant="top" src={Image3} />
              <Card.Body>
                <div className="star">
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                  <i><FaStar /></i>
                </div>
                <Card.Title>Product NAME</Card.Title>
                <div className="card_footer">
                  <div className='card_price' >
                    <p className='discount_price'>$4.00</p>
                    <p>$4.00</p>
                  </div>
                  <button className='custom_btn_card'><Link to="/">Shop Now</Link>  </button>
                </div>
              </Card.Body>
            </Card>

          </Slider>
        </div>
      </Container>
    </section>
  )
}

export default OurBestSell