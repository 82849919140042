import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";
import Logo from "../images/logo.png"
import HeaderNav from './HeaderNav';

const Header = () => {
  return (
    <>
      <div className="nav_bg">
        <Container>
          <Row>
            <Col sm={7}>
              <div className="logo_section">
                <Link to="/">
                  <img src={Logo} alt="" />
                </Link>
                <div className="logo_section_contact">
                  <p>Contact Us</p>
                  <Link to="#" className='link' >
                    6232366506
                  </Link>
                </div>
                <div class="vertical-line"></div>
                <div className="logo_section_contact">
                  <p>Email</p>
                  <Link to="#" className='link'>
                    rijoxavier81@gmail.com
                  </Link>
                </div>
              </div>
            </Col>

            <Col sm={5}>
              <HeaderNav />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Header