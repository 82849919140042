import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';



import SectionName from './SectionName'

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';


const OurBrands = () => {

  var setting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };


  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector(state => state.settings);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);


  useEffect(() => {
    AOS.init();
  }, []);


  // ************ CMS ************
  const brands_title = settings.find(setting => setting.name === 'brands_title');
  const our_brands_image = settings.find(setting => setting.name === 'our_brands_image');
  const our_brands_image_sec = settings.find(setting => setting.name === 'our_brands_image_sec');
  const baseImageUrl = 'https://backend.fronteda.devhub.digtalsdesigns.com/public/uploads/media/';
  const Logo = our_brands_image?.value ? `${baseImageUrl}${our_brands_image.value}` : ''; // Construct the full image URL
  const Logo2 = our_brands_image_sec?.value ? `${baseImageUrl}${our_brands_image_sec.value}` : ''; // Construct the full image URL

  return (
    <section>
      <SectionName title={brands_title?.value} />
      <section>
        <div className="container">
          <div className="slider_w">
            <Slider {...setting}>
              <div className='slider_imge' >
                <Link to='/shop?search=2'>
                  <img src={Logo} alt=""
                    data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear"
                  />
                </Link>
              </div>
              <div className='slider_imge'>
                <Link to='shop?search=1'>
                  <img src={Logo2} alt=""
                    data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear"
                  />
                </Link>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </section>
  )
}

export default OurBrands