import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import HeroImg from "../images/hero.jpg"
import HeroImg0 from "../images/hero-text.png"
import HeroImg1 from "../images/hero01.png"
import HeroImg2 from "../images/hero02.png"

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroSlider = () => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="hero-slider">
        <Slider {...settings}>
          <div className="hero-slider_img_bg" >
            <div className="hero_img">
              <img className="hero_img0" src={HeroImg} alt=""
              />
              <img className="hero_img_text" src={HeroImg0} alt=""
                data-aos="zoom-in" data-aos-duration="2000" data-aos-easing="linear"
              />
              <img className="hero_img1" src={HeroImg1} alt=""
                data-aos="fade-left" data-aos-duration="2000" data-aos-easing="linear"
              />
              <img className="hero_img2" src={HeroImg2} alt=""
                data-aos="fade-right" data-aos-duration="2000" data-aos-easing="linear"
              />
            </div>
          </div>

          <div className="hero-slider_img_bg" >
            <div className="hero_img">
              <img className="hero_img0" src={HeroImg} alt=""
              />
              <img className="hero_img_text" src={HeroImg0} alt=""
                data-aos="zoom-in" data-aos-duration="2000"
              />
              <img className="hero_img1" src={HeroImg1} alt=""
                data-aos="fade-left" data-aos-duration="2000"
              />
              <img className="hero_img2" src={HeroImg2} alt=""
                data-aos="fade-right" data-aos-duration="2000"
              />
            </div>
          </div>

          <div className="hero-slider_img_bg" >
            <div className="hero_img">
              <img className="hero_img0" src={HeroImg} alt=""
              // data-aos="fade-right" data-aos-duration="2000"
              />
              <img className="hero_img_text" src={HeroImg0} alt=""
                data-aos="zoom-in" data-aos-duration="2000"
              />
              <img className="hero_img1" src={HeroImg1} alt=""
                data-aos="fade-left" data-aos-duration="2000"
              />
              <img className="hero_img2" src={HeroImg2} alt=""
                data-aos="fade-right" data-aos-duration="2000"
              />
            </div>
          </div>

        </Slider>
      </div>

    </>
  );
}

export default HeroSlider