import axios from 'axios';

export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';



export const fetchSettings = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SETTINGS_REQUEST });

    try {
      const response = await axios.get('https://backend.fronteda.devhub.digtalsdesigns.com/api/theme-option');
      dispatch({
        type: FETCH_SETTINGS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_SETTINGS_FAILURE,
        payload: error.message
      });
    }
  };
};
