import React, { useEffect } from 'react'
import { Container, Row, Col, InputGroup, Form, Button } from 'react-bootstrap'
import Cards from "../images/Layer-7.png"

import { FaFacebookF, FaInstagramSquare, FaTwitter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';


const Footer = () => {

    
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.settings);

  useEffect(() => {
      dispatch(fetchSettings());
  }, [dispatch]);
  
  // ************ CMS ************
  const Copyright = settings.find(setting => setting.name === 'Copyright');
  const footer_text = settings.find(setting => setting.name === 'footer_text');
  const footer_logo = settings.find(setting => setting.name === 'footer_logo');
  const baseImageUrl = 'https://backend.fronteda.devhub.digtalsdesigns.com/public/uploads/media/';
  const Logo = footer_logo?.value ? `${baseImageUrl}${footer_logo.value}` : ''; // Construct the full image URL
  const fb_link = settings.find(setting => setting.name === 'fb_link');
  const tw_link = settings.find(setting => setting.name === 'tw_link');
  const in_link = settings.find(setting => setting.name === 'in_link');

  return (
    <>
      <footer>
        <section className='footer_section'>
          <Container>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col sm={5} data-aos="fade-right" data-aos-duration="2000" data-aos-easing="linear">
                <div className="news">
                  <h1>NEWSLETTER</h1>
                </div>
              </Col>
              <Col sm={6} data-aos="fade-left" data-aos-duration="2000" data-aos-easing="linear">
                <div className="">
                  <InputGroup className="footer_inputs">
                    <Form.Control placeholder="Enter Your Email Address" />
                    <Button className='card_btn2' id="button-addon2">Subscribe Now</Button>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className='footer_section2'>
          <Container>
            <Row>
              <Col sm={3}>
                <div className="footer_logo_section">
                  <div className="footer_logo">
                    <Link to="/">
                      <img src={Logo} alt="" />
                    </Link>
                  </div>
                  {/* <p>{footer_text?.value}</p> */}
                  <h2>Get Connected</h2>
                  <div className='footer_icon'>
                    <ul>
                      <li><Link to={fb_link?.value} target="_blank"><FaFacebookF /></Link></li>
                      <li><Link to={tw_link?.value} target="_blank"><FaTwitter /></Link></li>
                      <li><Link to={in_link?.value} target="_blank"><FaInstagramSquare /></Link></li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm={3}>
                <div class="footer-links">
                  <h5>Quick Links</h5>
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="about">About</Link></li>
                    <li><Link to="shop">Products </Link></li>
                    <li><Link to="blogs">Blogs</Link></li>
                  </ul>
                </div>
              </Col>
              <Col sm={3}>
                <div class="footer-links">
                  <h5>Products</h5>
                  <ul>
                    <li><Link to="faqs">Help & FAQs</Link></li>
                    <li><Link to="/#">Shipping</Link></li>
                    <li><Link to="/#">Returns & Exchanges</Link></li>
                  </ul>
                </div>
              </Col>
              <Col sm={3}>
                <div class="footer-links">
                  <h5>Outher Links</h5>
                  <ul>
                    <li><Link to="register">Sign In or Sign Up</Link></li>
                    <li><Link to="order-search">Order Tracking</Link></li>
                    <li><Link to="login">My Account</Link></li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className='footer_section3'>
          <Container>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col sm={6}>
                <p className='footer_section3_p'>
                {Copyright?.value}
                  {/* @Copyright. {new Date().getFullYear()} All Right Reserved. */}
                </p>
              </Col >
              <Col sm={6} className='footer_card' >
              <Link to="/"><img src={Cards} alt="" /></Link>
              </Col >
            </Row >
          </Container >
        </section >
      </footer>

    </>

  )
}

export default Footer